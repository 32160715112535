import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-grid-system'
import Seo from '../components/SEO'
import Toc from '../components/Toc'
import usePrism from '../hooks/use-prism'
import ArticleHeader from '../components/ArticleHeader'
import ContainerWrapper from '../components/ContainerWrapper'
import Hidden from '../components/Responsive/Hidden'
import Visible from '../components/Responsive/Visible'

const Style = styled.div`
  .article {
    position: relative;
  }


  summary.title {
  font-weight: 300;
  font-style: normal;
  }
  summary {
    position: relative;
    padding-bottom: 20px;
  }

  summary::marker {
    color: transparent;
  }

 details {
   border-style: solid;
   border-width: 1px;
   border-color: #E7E7E7;
   padding-left: 2em;
   padding-right: 2em;
   padding-top: 1em;
   padding-bottom: 1em;
 }

  summary::after {
    content:  "+";
    position: absolute;
    color: green;
    font-size: 2em;
    font-weight: bold;
    right: 1em;
    top: -.4em;
    transition: all 0.5s;
  }

  details[open] summary::after {
    color: red;
    transform: translate(5px,0) rotate(45deg);
  }

`

const ArticleTemplate = ({ children, toc, title, frontmatter, modifiedGmt, createdGmt, performTime, crumbs, slug }) => {
  const container = React.useRef()

  usePrism(container)
  return (
    <ContainerWrapper crumbs={crumbs} withFeedback>
      <Row nowrap={'nowrap'} nogutter>
        <Col style={{ minWidth: 0 }}>
          <Style>
            <Seo
              article={true}
              title={title}
              description={frontmatter.abstract}
              modifiedGmt={modifiedGmt}
              performTime={performTime}
            />
            <div ref={container} className={'article'}>
              <ArticleHeader
                frontmatter={frontmatter}
                toc={<Visible xs sm md><Toc toc={toc} inline={true} frontmatter={frontmatter} slug={slug}/></Visible>}
                modifiedGmt={modifiedGmt}
                createdGmt={createdGmt}
              />
              {children}
            </div>
          </Style>
        </Col>
        <Col xs={'content'} id={'toc2'} style={{ minWidth: 0 }}>
          <Hidden xs sm md style={{ width: '18rem' }}>
            {<Toc toc={toc} frontmatter={frontmatter} slug={slug}/>}
          </Hidden>
        </Col>
      </Row>
    </ContainerWrapper>
  )
}

export default ArticleTemplate
